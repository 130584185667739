import React, { Component } from "react";
import { Row, Col} from "reactstrap";



class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Stream töölaud</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item active">
                    Vali tegevus vasakust tulbast
                  </li>
                </ol>
              </div>
            </Col>
          </Row>

        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
