import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import config from 'react-global-configuration';


// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/authUtils";

//config.set({apiUrl: 'http://localhost/stream/api/public/'})
config.set({apiUrl: 'https://stream.etnobird.com/api/public/'})

const firebaseConfig = {
  apiKey: "AIzaSyBUctS2mlPY58Y-E1AypSUz7OO0zhlBLC8",
  authDomain: "themesbrand-admin.firebaseapp.com",
  databaseURL: "https://themesbrand-admin.firebaseio.com",
  projectId: "themesbrand-admin",
  storageBucket: "themesbrand-admin.appspot.com",
  messagingSenderId: "427667224207",
  appId: "1:427667224207:web:3b97af80b8b4824619a2fa",
  measurementId: "G-S4LDYNV7FY"
};

// init firebase backend
initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
    this.loadMapsJs()
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  loadMapsJs = () => {
    var script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCPe1cMpDrcjH-wxFIavDRvxJzmvn7sby8&libraries=places&callback=initMap';
    script.defer = true;

    // Attach your callback function to the `window` object
    window.initMap = function() {
      // JS API is loaded and available
    };

    // Append the 'script' element to 'head'
    document.head.appendChild(script);
          
  }

  render() {
    const Layout = this.getLayout();

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout
  };
};

export default connect(mapStateToProps, null)(App);
