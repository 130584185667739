import React, { Component, useState, memo, createRef } from "react";
import { Row, Col, Button, FormGroup, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import config from 'react-global-configuration';
import { AvForm, AvField, AvGroup, AvFeedback } from "availity-reactstrap-validation";
import moment from 'moment'
import { MDBDataTable } from "mdbreact";
import Back from "../../components/Back";



class Offer extends Component {
  constructor(props) {
    super(props);
    
    var authUser = JSON.parse(sessionStorage.getItem("authUser"))


    this.state = {
        authUser: authUser,
        companies: [],
        offer: '',
        companyCreated:false,
        company:'',
        locations: [],
        companyUuid: this.props.match.params.uuid,
        address: '', 
        latlng: '',
        companyValue: '',
        savingSuccess: false
    };

    this.getCompanies()

    
    if (this.props.match.params.uuid){
      this.getOffer()
  }

    
  }

  getCompanies = () => {
    fetch(config.get('apiUrl')+'companies',{
        headers: {
            Authorization: 'Bearer '+ this.state.authUser.access_token
        }
    })
    .then(res=>res.json())
    .then((data)=> {

        

        this.setState({
            companies: data.retailers
        })
        
    })
  }


  getOffer = () => {
    fetch(config.get('apiUrl')+'offer/'+this.state.companyUuid,{
      headers: {
          Authorization: 'Bearer '+ this.state.authUser.access_token
      }
  })
      .then(response => response.json())
      .then(json => {

        var offerMap = []
        for (var i = 0; i < this.state.companies.length; i++) {
          if (parseInt(this.state.companies[i].id) === parseInt(json.offer.retailer.id)){
            this.setState({
              company: i
            })
          }
        }

        for (var i = 0; i < json.offer.locations.length; i++) {
          offerMap.push(json.offer.locations[i].id)
        } 

          this.setState({
              offer: json.offer,
              locations: offerMap
            })
      })
  }


    editOffer = (event, values) => {
        this.setState({
          savingSuccess: false
        })
        var offerRequest = {
            id: this.state.offer.id,
            title: values.title,
            discountPercentage: values.discountPercentage,
            discountPrice: values.discountPrice,
            content: values.content,
            validFrom: values.validFrom,
            validTo: values.validTo,
            locations: values.locations,
            company: values.company
        }
        fetch(config.get('apiUrl')+'offer',{
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ this.state.authUser.access_token
            },
            body: JSON.stringify(offerRequest)
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            this.setState({
                offerCreated:true,
                offer: data.offer,
                offerUuid: data.offer.uuid,
                savingSuccess: true,
            })
        })
    }
    
    createOffer = (event, values) => {


        let companyValue = values.company.split(/,/)
        var offerRequest = {
            title: values.title,
            discountPercentage: values.discountPercentage,
            discountPrice: values.discountPrice,
            content: values.content,
            validFrom: values.validFrom,
            validTo: values.validTo,
            locations: values.locations,
            company: values.company
        }
        fetch(config.get('apiUrl')+'offer',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ this.state.authUser.access_token
            },
            body: JSON.stringify(offerRequest)
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            this.setState({
                offerCreated:true,
                offer: data.offer,
                offerUuid: data.offer.uuid,
                savingSuccess: true
            })
        })
    }





  render() {

    var defaultValue = 0
    if (this.props.match.params.uuid && this.state.offer == '') {
      return ''
    }

    if (this.props.match.params.uuid && this.state.offer) {
      defaultValue = this.state.offer.retailer.id
    }

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">{this.state.offer ? this.state.offer.title : 'Lisa pakkumine' } </h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Töölaud</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.offer ? this.state.offer.title : 'Lisa pakkumine' }</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                    <AvForm onValidSubmit={this.state.offer.uuid ? this.editOffer : this.createOffer}>
                    {this.state.savingSuccess ? 
                    <Alert color="success" >
                      Pakkumine salvestatud
                    </Alert> : '' }
                    {this.state.companies.length > 0  ?
                    <AvField type="select" name="company" label="Ettevõte" defaultValue={defaultValue} onChange={(e, value) => {
                            
                            for (var i = 0; i < this.state.companies.length; i++) {
                              if (parseInt(this.state.companies[i].id) === parseInt(value)){
                                console.log(i)
                                this.setState({
                                  company: i
                                })
                              }
                            } 
                        }}>
                            <option value="0">Vali ettevõte</option>
                        {
                            this.state.companies.map((type, idx) =>  <option value={type.id}>{type.name}</option>
                            )
                        }
                    </AvField>
                    :
                    ''
                    }

                    {this.state.company !== '' ?
                    <AvField 
                      type="select" 
                      defaultValue={this.state.locations} 
                      name="locations" 
                      label="Asukohad"
                      validate={{
                        required: { value: true}
                      }}
                      multiple>
                        {
                            this.state.companies[this.state.company].location.map((type, idx) => <option value={type.id}>{type.address}</option>
                            )
                        }
                    </AvField>
                    :
                    ''
                    }
                    
                    <AvField
                      name="title"
                      label="Nimi"
                      value={this.state.offer.title}
                      placeholder="Sisesta pakkumise nimi"
                      type="text"
                      errorMessage="Pakkumise nimi on kohustuslik"
                      validate={{
                        required: { value: true }
                      }}
                    />

                    <AvField
                      name="content"
                      label="Pakkumise sisu"
                      value={this.state.offer.content}
                      placeholder="Sisesta pakkumise sisu"
                      type="textarea"
                      errorMessage="Pakkumise sisu on kohustuslik"
                      validate={{
                        required: { value: true }
                      }}
                    />
                   
                    <AvField
                      name="discountPercentage"
                      label="Soodushind või -protsent"
                      value={this.state.offer.discount_percentage}
                      placeholder="Sisesta pakkumise soodushind või -protsent"
                      type="text"
                      
                    />
                    <AvField
                      name="validFrom"
                      label="Alguskuupäev"
                      value={moment(this.state.offer.valid_from).format("YYYY-MM-DD")}
                      placeholder="Sisesta alguskuupäev"
                      type="date"
                      
                    />
                    <AvField
                      name="validTo"
                      label="Lõppkuupäev"
                      value={moment(this.state.offer.valid_until).format("YYYY-MM-DD")}
                      placeholder="Sisesta lõppkuupäev"
                      type="date"
                      
                    />
                    <FormGroup className="mb-0">
                      <div>
                        <Button type="submit" color="primary" className="mr-1">
                          {this.state.offer ? 'Muuda' : 'Lisa' }
                        </Button>{" "}
                        <Back/>
                      </div>
                    </FormGroup>
                    </AvForm>
                    
                </div>
              </div>



            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default Offer;

