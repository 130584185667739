import React, { Component, useState, memo, createRef } from "react";
import { Row, Col, Button, FormGroup, Label } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import config from 'react-global-configuration';
import { AvForm, AvField, AvGroup, AvFeedback, AvInput } from "availity-reactstrap-validation";
import moment from 'moment'
import { MDBDataTable } from "mdbreact";
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Back from "../../components/Back";

const AddressField = memo(props => {

  const [address, setAddress] = useState(props.address)
  const [addressSelected, setAddressSelected] = useState(false)

  const handleChange = myAddress => {

    setAddress(myAddress)
    props.parentCallback("")
  };

  if (props.address === "reset" && addressSelected){
    setAddress("")
    setAddressSelected(false)
  }

  const handleSelect = myAddress => {
    
    geocodeByAddress(myAddress)
      .then(results => getLatLng(results[0]))
      .then(
        (latLng) => {
          setAddress(myAddress)
          setAddressSelected(true)
          console.log('Success', latLng)
          console.log(myAddress)
          props.parentCallback(myAddress, latLng)
        }
      )
      .catch(error => console.error('Error', error));
    
  };


  return (
    <PlacesAutocomplete
    value={address}
    onChange={handleChange}
    onSelect={handleSelect}
  >
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <div>
        <input
          {...getInputProps({
            placeholder: 'Sisesta aadress ...',
            className: 'location-search-input form-control',
          })}
        />
        <div className="autocomplete-dropdown-container">
          {loading && <div>Laen...</div>}
          {suggestions.map(suggestion => {
            const className = suggestion.active
              ? 'suggestion-item--active'
              : 'suggestion-item';
            // inline style for demonstration purpose
            const style = suggestion.active
              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
              : { backgroundColor: '#ffffff', cursor: 'pointer' };
            return (
              <div
                {...getSuggestionItemProps(suggestion, {
                  className,
                  style,
                })}
              >
                <span>{suggestion.description}</span>
              </div>
            );
          })}
        </div>
      </div>
    )}
  </PlacesAutocomplete>
    )

})

const TimeField = memo(props => {

  const hoursMinutes= props.value.split(":")
  const defaultValue = moment().hour(hoursMinutes[0]).minute(hoursMinutes[1]);

  const handleChange = (value) => {
    props.parentCallback(value, props.name)
  }

  return (
    <TimePicker defaultValue={defaultValue} onChange={handleChange} showSecond={false} minuteStep={15} />
  )
})

class Location extends Component {
  constructor(props) {
    super(props);
    
    var authUser = JSON.parse(sessionStorage.getItem("authUser"))

    this.form = createRef()

    this.state = {
        authUser: authUser,
        location:'',
        locationUuid: this.props.match.params.uuid,
        address: '', 
        latlng: '',
        deleted: false, 
        weekdays: ["E","T","K","N","R","L","P"]
    };

    this.getLocation()
    
  }

  getLocation = () => {

    fetch(config.get('apiUrl')+'location/'+this.state.locationUuid,{
        headers: {
            Authorization: 'Bearer '+ this.state.authUser.access_token
        }
    })
        .then(response => response.json())
        .then(json => {

            this.setState({
                location: json.location,
                latlng: {
                  lat: json.location.coordinates.coordinates[0],
                  lng: json.location.coordinates.coordinates[1],
                }
            })

        })
    }


    deleteAddress = () => {
      fetch(config.get('apiUrl')+'location/'+this.state.locationUuid,{
        method: 'DELETE',
        headers: {
            'Content-Type' : 'application/json',
            Authorization: 'Bearer '+ this.state.authUser.access_token
        }
    })
      .then(response => response.json())
      .then(json => {
        if(json.message="deleted"){
            this.setState({
              deleted: true
            })
        }
      })
    }


    updateAddress = (event, values) => {


     

      console.log(this.state.latlng)




        var addressRequest = {
            uuid: this.state.locationUuid,
            address: values.address,
            city: values.city,
            postalCode: values.postalCode,
            phone: values.phone,
            email: values.email,
            coordinates: this.state.latlng,
            start_1:values.start_1,
            close_1: values.end_1,
            closed_1: values.closed_1,
            start_2:values.start_2,
            close_2: values.end_2,
            closed_2: values.closed_2,
            start_3:values.start_3,
            close_3: values.end_3,
            closed_3: values.closed_3,
            start_4:values.start_4,
            close_4: values.end_4,
            closed_4: values.closed_4,
            start_5:values.start_5,
            close_5:values.end_5,
            closed_5: values.closed_5,
            start_6:values.start_6,
            close_6: values.end_6,
            closed_6: values.closed_6,
            start_7:values.start_7,
            close_7: values.end_7,
            closed_7: values.closed_7,
        }
        fetch(config.get('apiUrl')+'location',{
            method: 'PATCH',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ this.state.authUser.access_token
            },
            body: JSON.stringify(addressRequest)
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            this.setState({
              location: data.location,
              latlng: {
                lat: data.location.coordinates.coordinates[0],
                lng: data.location.coordinates.coordinates[1],
              }
          })
        })
    }

    handleCallback = (myAddress, latLng) => {
      this.form.current._inputs['address'].value = myAddress
      this.setState({
        address: myAddress, 
        latLng: latLng
      })

    }

    handleBusinessHoursCallback = (value,name) => {
      this.form.current._inputs[name].value = moment(value).format("HH:mm")
    }


  render() {

    if (this.props.match.params.uuid && this.state.location == '') {
      return ''
    }

    if (this.state.deleted){
        return <Redirect to={{
            pathname: "/company/"+this.props.match.params.cuuid,
          }}/>
    }

   

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">{this.state.location.address } </h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Töölaud</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.location.address }</li>
                </ol>
              </div>
            </Col>

          </Row>
          <AvForm onValidSubmit={this.updateAddress} ref={this.form}>
        <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <div className="row">
                    
                    <div className="col-6">
                      <AvGroup>
                        <AvField
                        tag={AddressField}
                        name="address"
                        label="Aadress"
                        type="text"
                        defaultValue={this.state.location.address}
                        address={this.state.location.address}
                        parentCallback={this.handleCallback}
                        required
                        />
                        <AvFeedback>Address on kohustuslik</AvFeedback>
                      </AvGroup>
                        <AvField
                        name="city"
                        label="Linn"
                        placeholder="Sisesta linn"
                        type="text"
                        defaultValue={this.state.location.city}
                        errorMessage="Linn on kohustuslik"
                        validate={{
                            required: { value: true },
                        }}
                        />
                        <AvField
                        name="postalCode"
                        label="Posti kood"
                        placeholder="Sisesta posti kood"
                        type="text"
                        defaultValue={this.state.location.postalCode}
                        errorMessage="Posti kood on kohustuslik"
                        validate={{
                            required: { value: true },
                        }}
                        />
                         <AvField
                        name="phone"
                        label="Telefon"
                        placeholder="Sisesta telefon"
                        type="text"
                        defaultValue={this.state.location.phone}
                        errorMessage="Telefon on kohustuslik"
                        validate={{
                            required: { value: true },
                        }}
                        />
                        <AvField
                        name="email"
                        label="E-kirja aadress "
                        placeholder="Sisesta e-kirja aadress"
                        type="email"
                        defaultValue={this.state.location.email}
                        errorMessage="Vigane e-posti aadress"
                        validate={{
                            required: { value: true },
                            email: { value: true }
                        }}
                        />
                       
                        </div>
                        <div className="col-6">
                        <Row>
                          <Col><label>Lahtiolekuajad</label></Col>
                        </Row>
                        <Row>
                          <Col>
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead className="thead-light">
                                <tr>
                                  <th>Päev</th>
                                  <th>Avamisaeg</th>
                                  <th>Sulgemisaeg</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.location.business_hours.map((hours,idx) => { 
                                  
                                  return(
                                  <tr>
                                  <th scope="row">{this.state.weekdays[idx]}</th>
                                  <td><AvField tag={TimeField} name={`start_${idx+1}`} type="text" value={hours.opened_at} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td><AvField tag={TimeField} name={`end_${idx+1}`}  type="text" value={hours.closed_at} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td>
                                  <AvGroup check>
                                    <AvInput type="checkbox" name={`closed_${idx+1}`}  checked={hours.closed ? "true": ""}/>
                                    <Label check for="checkbox">Suletud</Label>
                                  </AvGroup>
                                  </td>
                                </tr>)
                                })}
                                
                              
                              </tbody>
                            </table>
                          </div>
                          </Col>
                         
                        </Row>
     
                    </div>
                    <Row>
                        <Col>
                            <FormGroup className="mb-0">
                        <div>
                            <Button type="submit" color="primary" className="mr-1">
                            Salvesta
                            </Button>{" "}
                            <Button color="secondary" className="mr-1" onClick={()=>this.deleteAddress()}>
                            Kustuta
                            </Button>
                            <Back/>
                        </div>
                        </FormGroup>
                      
                    </Col>
                    </Row>
                
                </div>
                </div>
              </div>
            </div>
          </div>
          </AvForm>



        </div>
      </React.Fragment>
    );
  }
}

export default Location;

