import React, { Component, useState, memo, createRef } from "react";
import { Row, Col, Button, FormGroup, Label, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import config from 'react-global-configuration';
import { AvForm, AvField, AvGroup, AvFeedback, AvInput } from "availity-reactstrap-validation";
import moment from 'moment'
import { MDBDataTable } from "mdbreact";
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Back from "../../components/Back";

const AddressField = memo(props => {

  const [address, setAddress] = useState(props.address)
  const [addressSelected, setAddressSelected] = useState(false)

  const handleChange = myAddress => {

    setAddress(myAddress)
    props.parentCallback("")
  };

  if (props.address === "reset" && addressSelected){
    setAddress("")
    setAddressSelected(false)
  }

  const handleSelect = myAddress => {
    
    geocodeByAddress(myAddress)
      .then(results => getLatLng(results[0]))
      .then(
        (latLng) => {
          setAddress(myAddress)
          setAddressSelected(true)
          console.log('Success', latLng)
          console.log(myAddress)
          props.parentCallback(myAddress, latLng)
        }
      )
      .catch(error => console.error('Error', error));
    
  };


  return (
    <PlacesAutocomplete
    value={address}
    onChange={handleChange}
    onSelect={handleSelect}
  >
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <div>
        <input
          {...getInputProps({
            placeholder: 'Sisesta aadress ...',
            className: 'location-search-input form-control',
          })}
        />
        <div className="autocomplete-dropdown-container">
          {loading && <div>Laen...</div>}
          {suggestions.map(suggestion => {
            const className = suggestion.active
              ? 'suggestion-item--active'
              : 'suggestion-item';
            // inline style for demonstration purpose
            const style = suggestion.active
              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
              : { backgroundColor: '#ffffff', cursor: 'pointer' };
            return (
              <div
                {...getSuggestionItemProps(suggestion, {
                  className,
                  style,
                })}
              >
                <span>{suggestion.description}</span>
              </div>
            );
          })}
        </div>
      </div>
    )}
  </PlacesAutocomplete>
    )

})

const TimeField = memo(props => {

  const defaultValue = moment().hour(props.hour).minute(props.minute);

  const handleChange = (value) => {
    props.parentCallback(value, props.name)
  }

  return (
    <TimePicker defaultValue={defaultValue} onChange={handleChange} showSecond={false} minuteStep={15} />
  )
})

class Company extends Component {
  constructor(props) {
    super(props);
    
    var authUser = JSON.parse(sessionStorage.getItem("authUser"))
    this.form = createRef()

    this.state = {
        authUser: authUser,
        types: [],
        companyCreated:false,
        company:'',
        companyUuid: this.props.match.params.uuid,
        address: '', 
        latlng: '',
        savingSuccess: false
    };
    this.getTypes(authUser)
    

    if (this.props.match.params.uuid){
        this.getCompany()
    }
    
  }

  getTypes = (authUser) => {

    fetch(config.get('apiUrl')+'types',{
        headers: {
            'Content-Type' : 'application/json',
            Authorization: 'Bearer '+ authUser.access_token
        }
    })
        .then(response => response.json())
        .then(json => {
            this.setState({
                types: json.types
            })
        })
    }

  handleRowClick = (id) => {
      this.props.history.push(this.props.match.params.uuid + "/location/"+id)
  }

  getCompany = () => {

    fetch(config.get('apiUrl')+'company/'+this.state.companyUuid,{
        headers: {
            Authorization: 'Bearer '+ this.state.authUser.access_token
        }
    })
        .then(response => response.json())
        .then(json => {

            json.retailer.location.map(location => {
              location.clickEvent = e => this.handleRowClick(location.uuid)
              return null
          })

            this.setState({
                company: json.retailer
            })
        })
    }
    
    createCompany = (event, values) => {
        var companyRequest = {
            name: values.name,
            type: values.type
        }
        fetch(config.get('apiUrl')+'company',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ this.state.authUser.access_token
            },
            body: JSON.stringify(companyRequest)
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            this.setState({
                companyCreated:true,
                company: data.retailer,
                companyUuid: data.retailer.uuid,
                savingSuccess: true
            })
        })
    }

    editCompany = (event, values) => {
      this.setState({
        savingSuccess: false
      })
      var companyRequest = {
        name: values.name,
        type: values.type,
        id: this.state.company.id
    }
      fetch(config.get('apiUrl')+'company',{
          method: 'PATCH',
          headers: {
              'Content-Type' : 'application/json',
              Authorization: 'Bearer '+ this.state.authUser.access_token
          },
          body: JSON.stringify(companyRequest)
      })
      .then(res=>res.json())
      .then((data)=> {
          console.log(data)
          this.setState({
              companyCreated:true,
              company: data.retailer,
              companyUuid: data.retailer.uuid,
              savingSuccess: true
          })
      })
    }


    createAddress = (event, values) => {
      console.log(values)
        var addressRequest = {
            address: values.address,
            city: values.city,
            postalCode: values.postalCode,
            phone: values.phone,
            email: values.email,
            retailer_id: this.state.company.id,
            coordinates: this.state.latLng,
            start_1:moment(values.start_1).format("HH:mm"),
            close_1: moment(values.end_1).format("HH:mm"),
            closed_1: values.closed_1,
            start_2:moment(values.start_2).format("HH:mm"),
            close_2: moment(values.end_2).format("HH:mm"),
            closed_2: values.closed_2,
            start_3:moment(values.start_3).format("HH:mm"),
            close_3: moment(values.end_3).format("HH:mm"),
            closed_3: values.closed_3,
            start_4:moment(values.start_4).format("HH:mm"),
            close_4: moment(values.end_4).format("HH:mm"),
            closed_4: values.closed_4,
            start_5:moment(values.start_5).format("HH:mm"),
            close_5: moment(values.end_5).format("HH:mm"),
            closed_5: values.closed_5,
            start_6:moment(values.start_6).format("HH:mm"),
            close_6: moment(values.end_6).format("HH:mm"),
            closed_6: values.closed_6,
            start_7:moment(values.start_7).format("HH:mm"),
            close_7: moment(values.end_7).format("HH:mm"),
            closed_7: values.closed_7,
        }
        fetch(config.get('apiUrl')+'address',{
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                Authorization: 'Bearer '+ this.state.authUser.access_token
            },
            body: JSON.stringify(addressRequest)
        })
        .then(res=>res.json())
        .then((data)=> {
            console.log(data)
            this.form && this.form.current.reset()
            this.form.current._inputs['address'].value = ""
            this.setState({
              address: "reset"
            })
            this.getCompany()
        })
    }

    handleCallback = (myAddress, latLng) => {
      console.log(myAddress)
      console.log(latLng)
      console.log("tere")
      this.form.current._inputs['address'].value = myAddress
      this.setState({
        address: myAddress, 
        latLng: latLng
      })

    }

    handleBusinessHoursCallback = (value,name) => {
      console.log("bhcallb")
      console.log(value)
      console.log(name)
      this.form.current._inputs[name].value = value
    }


  render() {

    var defaultValue = 0
    if (this.props.match.params.uuid && this.state.company == '') {
      return ''
    }

    if (this.props.match.params.uuid && this.state.company) {
      defaultValue = this.state.company.type.id
    }
  

    const data = {
        columns: [
          {
            label: "Address",
            field: "address",
            sort: "asc",
            width: 150
          },
          {
            label: "Linn",
            field: "city",
            sort: "asc",
            width: 270
          },
        ],
        rows: this.state.company.location
        
      };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">{this.state.company ? this.state.company.name : 'Lisa ettevõte' } </h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Töölaud</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.company ? this.state.company.name : 'Lisa ettevõte' }</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                    <AvForm onValidSubmit={this.state.company.id ? this.editCompany : this.createCompany}>
                    {this.state.savingSuccess ? 
                    <Alert color="success" >
                      Ettevõte salvestatud
                    </Alert> : '' }

                    <AvField
                      name="name"
                      label="Nimi"
                      value={this.state.company.name}
                      placeholder="Sisesta firma nimi"
                      type="text"
                      errorMessage="Firma nimi on kohustuslik"
                      validate={{
                        required: { value: true }
                      }}
                    />
                    {this.state.types.length > 0 ?
                    <AvField type="select" name="type" defaultValue={defaultValue} label="Tüüp">
                        {
                            this.state.types.map((type, idx) =>
                                <option value={type.id}>{type.description}</option>
                            )
                        }
                    </AvField>
                    :
                    ''
                    }
                    <FormGroup className="mb-0">
                      <div>
                        <Button type="submit" color="primary" className="mr-1">
                          {this.state.company ? 'Muuda' : 'Lisa' }
                        </Button>{" "}
                        <Back/>
                      </div>
                    </FormGroup>
                    </AvForm>
                    
                </div>
              </div>

{this.state.company ? 
        <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <div className="row">
                <div className="col-6">
                <div className="row font-size-18 mb-4">Asukohad</div>
                <MDBDataTable responsive bordered data={data} />
                </div>
                <div className="col-6">
                    <div className="row font-size-18 mb-4">Lisa uus asukoht</div>
                    <AvForm onValidSubmit={this.createAddress} ref={this.form}>
                      <AvGroup>
                        <AvField
                        tag={AddressField}
                        name="address"
                        label="Aadress"
                        type="text"
                        address={this.state.address}
                        parentCallback={this.handleCallback}
                        required
                        />
                        <AvFeedback>Address on kohustuslik</AvFeedback>
                      </AvGroup>
                        <AvField
                        name="city"
                        label="Linn"
                        placeholder="Sisesta linn"
                        type="text"
                        errorMessage="Linn on kohustuslik"
                        validate={{
                            required: { value: true },
                        }}
                        />
                        <AvField
                        name="postalCode"
                        label="Posti kood"
                        placeholder="Sisesta posti kood"
                        type="text"
                        errorMessage="Posti kood on kohustuslik"
                        validate={{
                            required: { value: true },
                        }}
                        />
                         <AvField
                        name="phone"
                        label="Telefon"
                        placeholder="Sisesta telefon"
                        type="text"
                        errorMessage="Telefon on kohustuslik"
                        validate={{
                            required: { value: true },
                        }}
                        />
                        <AvField
                        name="email"
                        label="E-kirja aadress "
                        placeholder="Sisesta e-kirja aadress"
                        type="email"
                        errorMessage="Vigane e-posti aadress"
                        validate={{
                            required: { value: true },
                            email: { value: true }
                        }}
                        />
                        <Row>
                          <Col><label>Lahtiolekuajad</label></Col>
                        </Row>
                        <Row>
                          <Col>
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead className="thead-light">
                                <tr>
                                  <th>Päev</th>
                                  <th>Avamisaeg</th>
                                  <th>Sulgemisaeg</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row">E</th>
                                  <td><AvField tag={TimeField} name="start_1" type="text" hour={9} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td><AvField tag={TimeField} name="end_1" type="text" hour={17} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td>
                                  <AvGroup check>
                                    <AvInput type="checkbox" name="closed_1" />
                                    <Label check for="checkbox">Suletud</Label>
                                  </AvGroup>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">T</th>
                                  <td><AvField tag={TimeField} name="start_2" type="text" hour={9} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td><AvField tag={TimeField} name="end_2" type="text" hour={17} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td>
                                  <AvGroup check>
                                    <AvInput type="checkbox" name="closed_2" />
                                    <Label check for="checkbox">Suletud</Label>
                                  </AvGroup>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">K</th>
                                  <td><AvField tag={TimeField} name="start_3" type="text" hour={9} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td><AvField tag={TimeField} name="end_3" type="text" hour={17} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td>
                                  <AvGroup check>
                                    <AvInput type="checkbox" name="closed_3" />
                                    <Label check for="checkbox">Suletud</Label>
                                  </AvGroup>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">N</th>
                                  <td><AvField tag={TimeField} name="start_4" type="text" hour={9} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td><AvField tag={TimeField} name="end_4" type="text" hour={17} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td>
                                  <AvGroup check>
                                    <AvInput type="checkbox" name="closed_4" />
                                    <Label check for="checkbox">Suletud</Label>
                                  </AvGroup>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">R</th>
                                  <td><AvField tag={TimeField} name="start_5" type="text" hour={9} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td><AvField tag={TimeField} name="end_5" type="text" hour={17} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td>
                                  <AvGroup check>
                                    <AvInput type="checkbox" name="closed_5" />
                                    <Label check for="checkbox">Suletud</Label>
                                  </AvGroup>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">L</th>
                                  <td><AvField tag={TimeField} name="start_6" type="text" hour={9} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td><AvField tag={TimeField} name="end_6" type="text" hour={17} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td>
                                  <AvGroup check>
                                    <AvInput type="checkbox" name="closed_6" />
                                    <Label check for="checkbox">Suletud</Label>
                                  </AvGroup>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">P</th>
                                  <td><AvField tag={TimeField} name="start_7" type="text" hour={9} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td><AvField tag={TimeField} name="end_7" type="text" hour={17} minute={0} parentCallback={this.handleBusinessHoursCallback}/></td>
                                  <td>
                                  <AvGroup check>
                                    <AvInput type="checkbox" name="closed_7" />
                                    <Label check for="checkbox">Suletud</Label>
                                  </AvGroup>
                                  </td>
                                </tr>
                              
                              </tbody>
                            </table>
                          </div>
                          </Col>
                         
                        </Row>
                        <FormGroup className="mb-0">
                      <div>
                        <Button type="submit" color="primary" className="mr-1">
                          Lisa
                        </Button>{" "}
                        
                      </div>
                    </FormGroup>
                    </AvForm>
                </div>
                </div>
                </div>
              </div>
            </div>
          </div>
:
''
    }

            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default Company;

