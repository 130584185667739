import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import config from 'react-global-configuration';
import moment from 'moment'
import flat from 'flat'
import flatten from "flat";



class Offers extends Component {
  constructor(props) {
    super(props);
    
    var authUser = JSON.parse(sessionStorage.getItem("authUser"))
    this.state = {
        offers: [],
        authUser: authUser
    };

    this.getOffers()
  }

 

  handleRowClick = (id) => {
      console.log(id)
      this.props.history.push("/offer/"+id)
  }

  getOffers = () => {
      console.log(this.state.user)
    fetch(config.get('apiUrl')+'allOffers',{
        headers: {
            Authorization: 'Bearer '+ this.state.authUser.access_token
        }
    })
    .then(res=>res.json())
    .then((data)=> {

        data.offers.map(offer => {
           offer.clickEvent = e => this.handleRowClick(offer.uuid)
            return null
        })

        Object.keys(data.offers).forEach(function(key) {
            data.offers[key] = flatten(data.offers[key])
        });
        this.setState({
            offers: data.offers
        })
        
    })
}

  render() {
    const data = {
      columns: [
        {
          label: "Ettevõte",
          field: "retailer.name",
          sort: "asc",
          width: 150
        },
        {
          label: "Pakkumine",
          field: "title",
          sort: "asc",
          width: 270
        },
        {
          label: "Olek",
          field: "status",
          sort: "asc",
          width: 200
        }
      ],
      rows: this.state.offers
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Pakkumised</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Töölaud</Link>
                  </li>
                  <li className="breadcrumb-item active">Pakkumised</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <Link to="/newoffer" className="waves-effect">
                <Button color="primary" className="mr-1">
                               Lisa pakkumine
                            </Button>
                </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">

                  <MDBDataTable responsive bordered data={data} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default Offers;
